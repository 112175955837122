import PropTypes from 'prop-types';

import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import useRequest from 'core/hooks/useRequest';

import withTheme from 'core/components/theme';

import TagTopics from 'core/components/TagTopics';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import RubricTopics from 'site/components/RubricTopics';
import FooterAds from 'site/components/Ads/FooterAds';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import styles from './index.styl';


const limit = 18;

function TagPage({ location, history, match, theme }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: tagTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location, history, match,
      limit,
      include: 'image',
      fields: filterRequiredParams([Card1, Card4], 'fields'),
    }),
  ] });

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <TagTopics
          tag={tag}
          topics={tagTopics}
          isLoading={topicsAreLoading || tagIsLoading}
          titleComponent={({ children }) => (
            <h1 className={styles.title}>
              <style jsx>{`
                .${styles.title}
                  border-color ${theme.colors.active1}
              `}
              </style>
              {children}
            </h1>
          )}
          limit={limit}
        >
          {({ topics }) => {
            return  (
              <RubricTopics
                topics={topics}
                isLoading={topicsAreLoading}
                notHero
              />
            );
          }}
        </TagTopics>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

TagPage.propTypes = {
  theme: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withTheme(TagPage);
