import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import InfiniteTopic from 'core/components/InfiniteTopic';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import { resolve } from 'core/utils/env';

import FooterAds from 'site/components/Ads/FooterAds';
import { TopBanner } from 'site/components/Ads/mobile';

import TopicWrapper from 'site/pages/topic/TopicWrapper';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';


function topicRenderer(topic, enhancedTopic) {
  const {
    content,
    infinityIndex,
  } = enhancedTopic;

  return (
    <Fragment>
      {infinityIndex > 0 && (
        <Mobile>
          <TopBanner lazy />
        </Mobile>
      )}
      <TopicWrapper content={content} infinityIndex={infinityIndex}>
        <TopicHeader content={content} infinityIndex={infinityIndex} />
        <TopicContent content={content} />
        <TopicFooter />
      </TopicWrapper>
    </Fragment>
  );
}

function TopicPage(props) {
  const {
    isMobile,
  } = props;

  const { data, isLoading: topicIsLoading } = useRequest(topicQuery(props));

  if (topicIsLoading) return <PageLoader />;

  return (
    <Fragment>
      <InfiniteTopic
        content={data}
        rcmBlockId={resolve({
          '*': 'aca4995b2352440982435f562e39c815',
          'production': isMobile ? '5db160945c5d4f09b720e1b1c34c0cc5' : 'b4a769ed68d747959699979ea8a431c3',
        })}
      >
        {topicRenderer}
      </InfiniteTopic>
      <AdWrapper top={isMobile ? 20 : 40}>
        <FooterAds />
      </AdWrapper>
    </Fragment>
  );
}

TopicPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TopicPage);
