import { aboutQuery } from 'core/queries/about';
import useRequest from 'core/hooks/useRequest';
import AboutCore from 'core/components/About';


function AboutPage(props) {
  const { data, isLoading } = useRequest(aboutQuery(props));

  return <AboutCore content={data} isLoading={isLoading} />;
}

export default AboutPage;
