import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';

import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';

import Boroda from 'site/components/Boroda';
import InnerSideColumn from 'site/components/InnerSideColumn';


function Authors(props) {
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <H1>Авторы</H1>
        <Indent top={20} />
        <AuthorsPage rawAuthors={rawAuthors} isLoading={isLoading} />
        <Boroda showRecommender showPopular />
      </ColumnLayout>
    </PageIndent>
  );
}

export default Authors;
