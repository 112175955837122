import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { rawContentQuery } from 'core/queries/searchPage';

import SearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecSearch,
} from 'site/components/Ads/mobile';

import CardSearch from 'site/cards/CardSearch';

import filters from './filters';


function Search({ location }) {
  const { data, isLoading } = useRequest(rawContentQuery({ location, card: CardSearch }));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
        <SearchPage
          isLoading={isLoading}
          rawContent={data}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearch}
          card={CardSearch}
        />
        <Boroda showRecommender showPopular />
      </ColumnLayout>
    </PageIndent>
  );
}

Search.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Search;
