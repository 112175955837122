import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Helmet } from 'core/libs/helmet';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import useRequest from 'core/hooks/useRequest';
import PageLoader from 'core/components/Loader/PageLoader';

import Rubric from 'core/components/Rubric';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import RubricTitle from 'site/components/RubricTitle';
import Boroda from 'site/components/Boroda';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import FooterAds from 'site/components/Ads/FooterAds';


const limit = 18;
const fields = filterRequiredParams([Card1, Card4], 'fields');

function RubricPage(props) {
  const { location, match, history } = props;
  const {
    params: {
      level_1: rubricSlug,
    },
  } = match;

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    rawRubricQuery({ match, history }),
    rawTopicsQuery({
      location,
      match,
      history,
      limit,
      include: 'image',
      fields,
    }),
  ] });

  if (rubricIsLoading) return <PageLoader />;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn rubricSlug={rubricSlug} />}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          limit={limit}
          titleComponent={RubricTitle}
          isLoading={topicsAreLoading}
        >
          {({ topics, rawRubric: rubric }) => {
            const slug = rubric?.data?.attributes?.slug;
            let customMeta = null;

            if (slug === 'novyy-god') {
              const year = new Date().getFullYear() + 1;
              customMeta = (
                <Helmet>
                  <title>{`Новый год ${year} — все статьи и новости`}</title>
                  <meta name='description' content={`Новый год ${year} - подготовка к празднику: как украсить елку и выбрать подарки. В каком наряде лучше встречать Новый год и что приготовить.`} />
                </Helmet>
              );
            }

            return (
              <Fragment>
                {customMeta}
                <RubricTopics topics={topics} isLoading={topicsAreLoading} />
              </Fragment>
            );
          }}
        </Rubric>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

RubricPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};


export default RubricPage;
