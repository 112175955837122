import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawTopicsQuery } from 'core/queries/listPage';
import useRequest from 'core/hooks/useRequest';

import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import RubricTitle from 'site/components/RubricTitle';
import Boroda from 'site/components/Boroda';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import FooterAds from 'site/components/Ads/FooterAds';

const title = 'Статьи';
const description = 'Все статьи Летидора для родителей. Советы экспертов, статьи лучших авторов, лайфхаки и многое другое.';

const fields = filterRequiredParams([Card1, Card4], 'fields');

function ArticlesPage(props) {
  const { location, history } = props;
  const { data: rawData, isLoading: topicsAreLoading } = useRequest(rawTopicsQuery({
    location,
    history,
    limit: 18,
    include: 'image',
    fields,
    topic_type: 'article,video,gallery',
  }));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <ListPage
          title={title}
          description={description}
          rawData={rawData}
          isLoading={topicsAreLoading}
        >
          {({ content }) => (
            <Fragment>
              <RubricTitle title={title} />
              <Indent bottom={40} />
              <RubricTopics topics={content} isLoading={topicsAreLoading} />
            </Fragment>
          )}
        </ListPage>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

ArticlesPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};


export default ArticlesPage;
