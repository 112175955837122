import Topic from 'core/components/Topic';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import PageLoader from 'core/components/Loader/PageLoader';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';
import { IndentWrap } from 'site/components/Wrappers';


function CommentsPage(props) {
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  return (
    <Topic content={data}>
      {content => {
        const {
          attributes: {
            link,
            is_comments_disabled: isCommentsDisabled,
          },
        } = content;

        return (
          <TopicWrapper content={content}>
            <IndentWrap bottom={40}>
              <TopicHeader content={content} comments />
              <Link
                to={link}
                type='secondary'
              >
                <Button size='small'>Вернуться к статье</Button>
              </Link>
            </IndentWrap>

            <Comments xid={link} isCommentsDisabled={isCommentsDisabled} />
          </TopicWrapper>
        );
      }}
    </Topic>
  );
}


export default CommentsPage;
