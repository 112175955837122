import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import Bouesque from 'core/components/Bouesque';
import SelfPromo from 'core/components/SelfPromo';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import PageLoader from 'core/components/Loader/PageLoader';
import StickyPortal from 'core/components/StickyPortal';
import AdWrapper from 'core/components/Ad/AdWrapper';

import BreakpointSwitcher from 'site/components/BreakpointSwitcher';
import Boroda from 'site/components/Boroda';
import KidsQuestions from 'site/components/KidsQuestions';
import FooterAds from 'site/components/Ads/FooterAds';

import useMainContent from './useMainConent';
import {
  Ad240x400,
  Ad240x400Second,
  Context,
  Parallax,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing2,
  Listing3,
  Listing4,
} from 'site/components/Ads/mobile';

import { PartnersVertical } from 'site/components/Partners';

import FirstBlock from './FirstBlock';
import PersonalExperience from './PersonalExperience';
import MainParents from './MainParents';
import MainPhoto from './MainPhoto';
import MainVideo from './MainVideo';
import MainExperts from './MainExperts';
import MainDosug from './MainDosug';
import MainPregnancy from './MainPregnancy/index.js';

const indent = 40;

function MainPage({ isDesktop }) {
  const {
    firstBlockTopics,
    personalExperienceTopics,
    parentsTopics,
    expertsTopics,
    dosugTopics,
    mainArticles,
    photoTopics,
    videoTopics,
    isLoading,
  } = useMainContent();

  if (isLoading) return <PageLoader />;

  return (
    <Page>
      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <Fragment>
              <Bouesque />
              <Indent bottom={indent} />
              <StickyPortal listenStickyRemoveEvent>
                <AdWrapper bottom={indent}>
                  <Ad240x400 />
                  <YandexMetrikaGoalSender
                    show='child_questions_desktop_sidebar_main_show'
                    click='child_questions_desktop_sidebar_main_click'
                    skipTargetCheck
                    checkScrollY
                  >
                    <KidsQuestions indent={{ bottom: indent }} />
                  </YandexMetrikaGoalSender>
                  <SelfPromo />
                  <Indent bottom={indent} />
                  <Ad240x400Second />
                  <PartnersVertical />
                </AdWrapper>
              </StickyPortal>
            </Fragment>
          )}
        >
          <FirstBlock
            content={firstBlockTopics}
            mainArticles={mainArticles}
            isDesktop={isDesktop}
          />
          <Indent top={indent} bottom={indent}>
            <PersonalExperience content={personalExperienceTopics} />
          </Indent>
          <BreakpointSwitcher
            desktop={<AdWrapper bottom={indent}><SuperFooter /></AdWrapper>}
            mobile={<AdWrapper bottom={indent / 2}><Listing2 /></AdWrapper>}
          />
          <MainParents content={parentsTopics} />
          <Indent bottom={indent} />
          <MainPhoto content={photoTopics} isDesktop={isDesktop} />
        </ColumnLayout>
      </PageIndent>
      <BreakpointSwitcher
        desktop={(
          <AdWrapper top={indent}>
            <Parallax />
          </AdWrapper>
        )}
      />
      <Indent bottom={indent} />
      <PageIndent>
        <ColumnLayout>
          <MainVideo content={videoTopics} isDesktop={isDesktop} />
          <BreakpointSwitcher
            desktop={<AdWrapper top={indent}><Context /></AdWrapper>}
            mobile={<AdWrapper top={indent / 2}><Listing3 /></AdWrapper>}
          />
          <Indent top={indent} bottom={indent}>
            <MainExperts content={expertsTopics} />
          </Indent>
          <MainDosug content={dosugTopics} isDesktop={isDesktop} />
          <BreakpointSwitcher
            mobile={<AdWrapper top={indent / 2}><Listing4 /></AdWrapper>}
          />
          <Indent bottom={indent} />
          <MainPregnancy isDesktop={isDesktop} />
          <Boroda showDiscussed showPopular />
        </ColumnLayout>
        <FooterAds contextCount={2} />
      </PageIndent>
    </Page>
  );
}

MainPage.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(MainPage);
