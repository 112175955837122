import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawTopicsQuery } from 'core/queries/listPage';
import useRequest from 'core/hooks/useRequest';

import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import { IndentWrap } from 'site/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import RubricTitle from 'site/components/RubricTitle';
import Boroda from 'site/components/Boroda';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import FooterAds from 'site/components/Ads/FooterAds';


const title = 'Новости';
const fields = filterRequiredParams([Card1, Card4], 'fields');

function NewsPage({ location, history }) {
  const { data: rawData, isLoading: topicsAreLoading } = useRequest(rawTopicsQuery({
    location,
    history,
    limit: 18,
    include: 'image',
    fields: fields,
    topic_type: 'news',
  }));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <ListPage
          rawData={rawData}
          title={title}
          isLoading={topicsAreLoading}
        >
          {({ content }) => (
            <Fragment>
              <RubricTitle title={title} />
              <IndentWrap bottom={40} />
              <RubricTopics topics={content} isLoading={topicsAreLoading} />
            </Fragment>
          )}
        </ListPage>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default NewsPage;
